export enum FilterType {
  State = "STATE",
  Coast = "COAST",
  Region = "REGION",
}
export const FilterTypeDisplay = [
  { name: "State", value: FilterType.State },
  { name: "Coast", value: FilterType.Coast },
  { name: "Region", value: FilterType.Region },
];
