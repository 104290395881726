import { RestService } from "@/app/core/services/core/RestService";
import { PopularRoutesContract } from "@/app/core/interfaces/services/check-route/PopularRoutesContract";
import { HighestPayingContract } from "@/app/core/interfaces/services/check-route/HighestPayingContract";
import { Order } from "@/app/core/models/domain/Order";

export class CheckRouteService extends RestService {
  constructor() {
    super("/check-routes", "check-routes", "");
  }

  async getMyRoutes(id: number, page: number, limit: number): Promise<any> {
    return await this.httpGetAll<Order>(`${this.apiHref()}/my-routes/${id}`, {
      page,
      limit,
    });
  }

  async getPopularRoutes(type: any, page: number, limit: number): Promise<any> {
    return await this.httpGetAll<PopularRoutesContract>(`${this.apiHref()}/popular`, {
      type,
      page,
      limit,
    });
  }

  async getHighestPaying(type: any, page: number, limit: number): Promise<any> {
    return await this.httpGetAll<HighestPayingContract>(`${this.apiHref()}/highest-paying`, {
      type,
      page,
      limit,
    });
  }
}
